import * as React from "react";
import {
    DataGridPro,
    GridColumns,
    GridRenderCellParams,
    GridRowId,
    GridSelectionModel,
} from "@mui/x-data-grid-pro";
import { ApplicationFeature } from "../../../../services/types/applicationFeatureTypes";
import { addCustomDataGridFilters } from "../../../../components/common/customDataGridFilters/customDataGridFilters";
import CclDataGridToolbar from "../../../../components/common/cclDataGridToolbar/cclDataGridToolbar";

export interface ApplicationFeaturesDataGridProProps {
    features: ApplicationFeature[];
    triggerUpdateFlow: (id: number) => void;
    loading: boolean;
    showResultBar?: boolean;
    resultBarText?: string;
}

export const ApplicationFeaturesDataGridPro: React.FC<ApplicationFeaturesDataGridProProps> = (
    props
) => {
    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
    const [selectionModel, setSelectionModel] = React.useState<GridRowId[]>([]);

    const handleSelection = (ids: GridSelectionModel) => {
        if (!ids) {
            return;
        }
        const selectedRowsData = ids.map((id) =>
            props.features.find((row) => row.applicationFeatureId === id)
        );
        setSelectedRows(selectedRowsData);
    };

    const COLUMNS = [
        {
            field: "name",
            headerName: "Feature",
            type: "string",
            flex: 0.8,
        },
        {
            field: "description",
            headerName: "Description",
            type: "string",
            flex: 1,
        },
        {
            field: "active",
            headerName: "Enabled",
            renderCell: (params: GridRenderCellParams) => {
                return params.row.active ? "Yes" : "No";
            },
            type: "string",
            flex: 0.6,
        },
        {
            field: "createdUser",
            headerName: "Created By",
            flex: 0.75,
            type: "string",
        },
        {
            field: "createdUtc",
            headerName: "Created",
            renderCell: (params: GridRenderCellParams) => {
                return new Date(params.row.createdUtc).toLocaleDateString();
            },
            type: "string",
            flex: 1.1,
        },
        {
            field: "modifiedUser",
            headerName: "Modified By",
            flex: 1,
            type: "string",
        },
        {
            field: "modifiedUtc",
            headerName: "Modified",
            renderCell: (params: GridRenderCellParams) => {
                return new Date(params.row.modifiedUtc).toLocaleDateString();
            },
            flex: 0.8,
            type: "string",
        },
    ];

    return (
        <DataGridPro
            rows={props.features}
            columns={addCustomDataGridFilters(COLUMNS as GridColumns<any>)}
            loading={props.loading}
            components={{ Toolbar: CclDataGridToolbar }}
            componentsProps={{
                toolbar: {
                    showSaveState: true,
                    savestate: null,
                    clearstate: null,
                    miscButtons: [
                        {
                            id: "change-enabled",
                            caption: selectedRows[0]?.active ? "Disable Feature" : "Enable Feature",
                            label: selectedRows[0]?.active ? "Disable Feature" : "Enable Feature",
                            returnSelected: false,
                            restricted: false,
                            disable: selectedRows.length !== 1,
                            onClick: () => {
                                props.triggerUpdateFlow(selectedRows[0].applicationFeatureId);
                            },
                        },
                    ],
                    btnClick: () => props.triggerUpdateFlow(selectedRows[0].applicationFeatureId),
                    hideSettingsButtons: true,
                    anySelected: null,
                },
            }}
            getRowId={(row: ApplicationFeature) => row.applicationFeatureId}
            initialState={{
                sorting: {
                    sortModel: [{ field: "name", sort: "asc" }],
                },
            }}
            checkboxSelection={true}
            disableMultipleSelection={true}
            disableSelectionOnClick
            selectionModel={selectionModel}
            onSelectionModelChange={(id: GridSelectionModel) => {
                if (id.length > 1) {
                    const selectionSet = new Set(selectionModel);
                    const result = id.filter((s) => !selectionSet.has(s));
                    handleSelection(result);
                    setSelectionModel(result);
                } else {
                    handleSelection(id);
                    setSelectionModel(id);
                }
            }}
        />
    );
};

export default ApplicationFeaturesDataGridPro;
